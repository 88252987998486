import React, { useState, useEffect } from 'react';
import { Button, Table, Input, Select, Space, Drawer, Row, Col, message, Popconfirm } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined, LeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import FormItem from 'antd/es/form/FormItem';
import { metaDataLoad, metaDataAdd, metaDataDelete, metaDataUpdate } from '../../../services/admin/adminService';
import '../assets/styles/common.css';
const { Option } = Select;


const MetadataComponent = () => {
    const [childVisible, setChildVisible] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [fields, setFields] = useState([]);
    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldType] = useState('');
    const [fieldLength, setFieldLength] = useState('');
    const [metadataName, setMetadataName] = useState('');
    const [editRecordKey, setEditRecordKey] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [filterGroupsData, setFilterGroupsData] = useState(null);
    const [metadataDescription, setMetadataDescription] = useState('');
    const [metadataData, setMetadataData] = useState([
        {
            key: '1', metadataName: 'Metadata 1', metadataFields: [
                { fieldName: 'Field 1', fieldType: 'Text', fieldLength: '10' },
                { fieldName: 'Field 2', fieldType: 'Numeric', fieldLength: '5' }
            ]
        },
        {
            key: '2', metadataName: 'Metadata 2', metadataFields: [
                { fieldName: 'Field 3', fieldType: 'Decimal', fieldLength: '8' },
                { fieldName: 'Field 4', fieldType: 'DateTime', fieldLength: '4' }
            ]
        },
    ]);
    const [editingKey, setEditingKey] = useState(null);
    const [responseSearchText, setResponseSearchText] = useState('');
    const [testdataData, setTestdataData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [textCase, setTextCase] = useState('');
    const [fieldInputType, setFieldInputType] = useState('');
    const [selectedField, setSelectedField] = useState(null);
    const showChildForm = (record) => {
        if (record) {
            setMetadataName(record.metadataName);
            setMetadataDescription(record.metadataDescription);
            setFields(record.metadataFields);
            setEditingKey(record.key);
        } else {
            setMetadataName('');
            setMetadataDescription('');
            setFields([]);
            setEditingKey(null);
        }
        setChildVisible(true);
    };
    const handleResponseSearch = (value) => {
        setResponseSearchText(value);
    };
    const closeChildForm = () => {
        setMetadataName('');
        setMetadataDescription('');
        setFields([]);
        setChildVisible(false);
        setEditingKey(null);
    };

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        // Reset form inputs
        setFieldName('');
        setFieldType('');
        setFieldLength('');
        setFieldInputType('');
        setTextCase('');
        setEditRecordKey(null); // Reset the editing record key

    };

    // Ensure fetchPopulateData function is reusable
    const fetchPopulateData = async () => {
        setLoading(true);
        try {
            const response = await metaDataLoad();
            if (response !== undefined) {
                setTestdataData(response);
                setFilteredData(response);
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch the data initially and after every change
    useEffect(() => {
        fetchPopulateData();
    }, []);
    // Modify handleAddField to trigger auto-refresh
    const handleAddField = async () => {
        // Check if fieldName is empty
        if (!fieldName || fieldName.trim() === '') {
            message.error("Field name cannot be empty. Please provide a valid field name.");
            return;
        }
    
        setLoading(true);
    
        const newField = {
            key: fields.length + 1,
            fieldName,
            fieldType,
            fieldLength,
            fieldInputType,
            textCase
        };
    
        // Add or update field in the local state
        if (editRecordKey !== null) {
            setFields(fields.map(field => field.key === editRecordKey ? newField : field));
        } else {
            setFields([...fields, newField]);
        }
    
        // Reset field inputs
        setFieldName('');
        setFieldType('');
        setFieldLength('');
        setFieldInputType('');
        setTextCase('');
        closeDrawer();
    
        try {
            const result = await metaDataAdd(editRecordKey, fieldName, fieldType, fieldInputType, textCase);
            if (result === 'true') {
                message.error("Already exists. Please choose a different name.");
                return;
            }
            if (result) {
                message.success("Successfully added or updated");
                // Auto-refresh after adding/updating
                fetchPopulateData();
            }
            
        } catch (error) {
            console.error('Error handling field data:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleSaveMetadata = () => {
        const newMetadata = {
            key: metadataData.length + 1,
            metadataName,
            metadataDescription,
            metadataFields: fields
        };
        setMetadataData([...metadataData, newMetadata]);
        closeChildForm();
    };

    const handleUpdateMetadata = () => {
        const updatedMetadataData = metadataData.map((item) =>
            item.key === editingKey
                ? {
                    ...item,
                    metadataName,
                    metadataDescription,
                    metadataFields: fields,
                }
                : item
        );
        setMetadataData(updatedMetadataData);
        closeChildForm();
    };
    const handleEditField = async (record) => {
        try {

            setLoading(true);
            const data = await metaDataUpdate(record.parameter_id); // Fetch data based on parameter_id
            setEditRecordKey(record.parameter_id);
            // Populate drawer fields with fetched data
            setFieldName(record.parameter_name);
            setFieldType(record.parameter_data_type);
            setFieldInputType(record.communication_type);
            setTextCase(record.type_case);

            // Set the selected field to update or delete it later
            setSelectedField(data);

            // Open the drawer
            showDrawer();
        } catch (error) {
            console.error('Error fetching field data:', error);
            message.error('Failed to load field data');
        } finally {
            setLoading(false);
        }
    };




    const handleDeleteField = async (record) => {
        try {
            const response = await metaDataDelete(record.parameter_id);

            if (response.success) {
                message.success('Deleted successfully!');
                fetchPopulateData();
            } else {
                message.error('Failed to delete component.');
            }
        } catch (error) {
            message.error('Error deleting component.');
        }
    };

    const handleResponseSearchClear = () => {
        setResponseSearchText('');

    }

    const columns = [
        {
            title: 'Metadata Name',
            dataIndex: 'metadataName',
            key: 'metadataName',
        },
        {
            title: 'Metadata Fields',
            dataIndex: 'metadataFields',
            key: 'metadataFields',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" icon={<EyeOutlined />} onClick={() => showChildForm(record)}>
                        View
                    </Button>
                </Space>
            ),
        },
    ];

    const fieldsColumns = [
        { title: 'Field Name', dataIndex: 'fieldName', key: 'fieldName' },
        { title: 'Field Type', dataIndex: 'fieldType', key: 'fieldType' },
        { title: 'Length', dataIndex: 'fieldLength', key: 'fieldLength' },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => handleEditField(record)} icon={<EditOutlined />} />
                    <Button type="link" onClick={() => handleDeleteField(record)} icon={<DeleteOutlined />} danger />
                </Space>
            ),
        },
    ];

    const TestCols = [
        {
            title: 'Parameter Name',
            dataIndex: 'parameter_name',
            key: 'parameter_name',
            sorter: (a, b) => a.parameter_name.localeCompare(b.parameter_name)
        },
        {
            title: 'Parameter Data Type',
            dataIndex: 'parameter_data_type',
            key: 'parameter_data_type',
            hidden: 'true',
            sorter: (a, b) => a.parameter_data_type.localeCompare(b.parameter_data_type)
        },
        {
            title: 'Voice Type',
            dataIndex: 'communication_type',
            key: 'communication_type',
            hidden: 'true',
            sorter: (a, b) => {
                const nameA = a.communication_type || '';
                const nameB = b.communication_type || '';
                return nameA.localeCompare(nameB);
            }
        },
        {
            title: 'Text type case',
            dataIndex: 'type_case',
            key: 'type_case',
            hidden: 'true',
            sorter: (a, b) => {
                const nameA = a.type_case || '';
                const nameB = b.type_case || '';
                return nameA.localeCompare(nameB);
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => handleEditField(record)} icon={<EditOutlined />} />
                    <Popconfirm
                        title="Are you sure you want to delete this record?"
                        onConfirm={() => handleDeleteField(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    //#region UseEffect
    useEffect(() => {
        const filtered = testdataData.filter(item =>
            item.parameter_name.toLowerCase().includes(responseSearchText.toLowerCase())
        );
        setFilteredData(filtered);
    }, [responseSearchText, testdataData]);

    useEffect(() => {
        async function fetchPopulateData() {
            setLoading(true);
            try {
                const response = await metaDataLoad();
                if (response !== undefined) {
                    setTestdataData(response);
                    setFilteredData(response);
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchPopulateData();
    }, []);
    //#endregion

    return (
        <div>
            {!childVisible && (
                <> {/* <Button type="primary" style={{ marginBottom: 16 }} onClick={() => showChildForm(null)}>
                Add Metadata
            </Button> */}
                    {/* <Table dataSource={metadataData} columns={columns} /> */}
                    <h2>Metadata Group</h2>
                    <Row gutter={7} style={{ marginBottom: 16 }}>
                        <Col span={16} >
                            <Button type="primary" onClick={showDrawer}>
                                Add Metadata
                            </Button>
                        </Col>
                        <Col span={5}>

                            <Input
                                className='cus-antd-white'
                                placeholder="Search parameter name"
                                value={responseSearchText}
                                onChange={e => handleResponseSearch(e.target.value)}
                                style={{ width: 240 }}
                            />

                        </Col>
                        <Col span={3}>
                            <Button type='text' shape='default' onClick={handleResponseSearchClear}>
                                <CloseCircleOutlined />
                            </Button>
                        </Col>
                    </Row>
                    <div id="testDemo">
                        {/* <Table dataSource={TestdataData} columns={TestCols} /> */}
                        <Table
                            columns={TestCols}
                            dataSource={filteredData}
                            loading={loading}
                            rowKey="parameter_id"
                            className='cus-antd-white'
                            bordered={true}
                        />
                    </div>


                    <Drawer
                        title="Add Metadata Field"
                        onClose={closeDrawer}
                        open={drawerVisible}
                        className="custom-drawers cus-drawer-sm"
                        style={{ width: "375px !important;" }}
                        footer={
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
                                    Cancel
                                </Button>
                                <Button onClick={handleAddField} type="primary">
                                    {editRecordKey ? 'Update Field' : 'Add Field'}
                                </Button>
                            </div>
                        }
                    ><FormItem>
                         <label for="fieldName">Field Name: <span style={{ color:"red" }}>*</span></label>
                                <Input
                                    placeholder="Field Name"
                                    value={fieldName}
                                    onChange={e => setFieldName(e.target.value)}
                                    style={{ marginBottom: 16 }}
                                />
                            <label>Field Type:</label>
                            <Select
                                placeholder="Select Field Type"
                                value={fieldType}
                                onChange={value => setFieldType(value)}
                                style={{ marginBottom: 16, width: '100%' }}
                            >
                                <Option value="Numeric">Numeric</Option>
                                <Option value="Text">Text</Option>
                                <Option value="Decimal">Decimal</Option>
                                <Option value="Boolean">Boolean</Option>
                                <Option value="DateTime">DateTime</Option>
                                <Option value="Date">Date</Option>
                                <Option value="Time">Time</Option>
                            </Select>
                            {/* <label>Length:</label>
                        <Input
                            placeholder="Length"
                            type="number"
                            value={fieldLength}
                            onChange={e => setFieldLength(e.target.value)}
                            style={{ marginBottom: 16 }}
                        /> */}
                            <label>Voice Message Case:</label><br />
                            <Select value={fieldInputType} style={{ width: "330px" }} onChange={(value) => setFieldInputType(value)}>
                                <Option value="caps">Caps</Option>
                                <Option value="small">Small</Option>
                            </Select><br /><br />
                            <label>Text Message Case:</label><br />
                            <Select value={textCase} style={{ width: "330px" }} onChange={(value) => setTextCase(value)}>
                                <Option value="caps">Caps</Option>
                                <Option value="small">Small</Option>
                            </Select>
                        </FormItem>

                    </Drawer>
                </>
            )}


            {childVisible && (
                <>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button onClick={closeChildForm} type="link" style={{ marginBottom: 16 }} icon={<LeftOutlined />}>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <FormItem>Metadata Name:</FormItem>
                            <Input
                                placeholder="Metadata Name"
                                value={metadataName}
                                className='cus-antd-white'
                                onChange={e => setMetadataName(e.target.value)}
                                style={{ marginBottom: 16 }}
                            />
                        </Col>
                        <Col span={8}>
                            <FormItem>Metadata Description:</FormItem>
                            <Input
                                placeholder="Metadata Description"
                                value={metadataDescription}
                                className='cus-antd-white'
                                onChange={e => setMetadataDescription(e.target.value)}
                                style={{ marginBottom: 16 }}
                            />
                        </Col>
                        <Col span={8}>
                            <Button type="primary" onClick={showDrawer} style={{ marginTop: 36 }}>
                                Add Field
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={fieldsColumns}
                        dataSource={fields}
                        pagination={false}

                    />



                    <Button
                        type="primary"
                        onClick={editingKey ? handleUpdateMetadata : handleSaveMetadata}
                        style={{ marginTop: 16 }}
                    >
                        {editingKey ? 'Update Metadata' : 'Save Metadata'}
                    </Button>
                </>
            )}
        </div>

    );
};

export default MetadataComponent;
